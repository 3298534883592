import axios from 'axios'
const url = '/clientes/cuentacorriente'
export default {

    index(paginacion) {
        return axios.get(url)
    },

    show(id) {

        return axios.get(`${url}/${id}`)
    },

    open(data) {

        return axios.post(`${url}`, data)
    },

    update(data) {
        console.log(data);

        return axios.put(`${url}/${data.id}`, data)
    },


    delete(cliente_id) {
        console.log(cliente_id);
        return axios.delete(`${url}/${cliente_id}`)


    },
    set_habilitado(cliente_id) {
        console.log(cliente_id);
        return axios.get(`${url}/${cliente_id}?habilitar=true`)


    },
    set_tiene_limite_cuenta(cliente_id) {
        console.log(cliente_id);
        return axios.get(`${url}/${cliente_id}?limite_cuenta=true`)


    },
    
    movimientos_cta_cte(cliente_id, paginacion) {
        console.log(cliente_id);
        return axios.get(`/clientes/cuenta/movimientos?c=${cliente_id}&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)


    },
    pagar(data) {

        return axios.post('/comprobantes/recibo', data)
    },


}