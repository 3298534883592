import axios from 'axios'

export default {
    index(paginacion) {
        return axios.get(`/comprobantes/comprobantes?esVenta=false&doc=FC|NP&page=${paginacion.page}&page_size=${paginacion.rowsPerPage}`)
    },

    show(id) {
    },

    update(id, data) {

    },
    anular(id) {
        var url = `/comprobantes/compra/eliminar/${id}`

        return axios.get(url)

    },

    create(data) {
    },
    payment(data) {
        var url = `/comprobantes/compra/pagar`
        return axios.post(url, data)
    },
    actualizarFechaContable(id, fecha_contable) {
        var url = `/comprobantes/compra/actualizar/${id}`
        var data = { fecha_contable: fecha_contable }
        return axios.put(url, data)
    }


}