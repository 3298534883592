import axios from 'axios'
const url = '/contabilidad/plancuentas'

export default {
    create(data) {
        return axios.post(url, data)
    },
    update(data) {
        return axios.put(`${url}/${data.id}`, data)
    },
    async indexPlanCuentas(params = {}) {
        return axios.get(url, { params }); // Enviar parámetros dinámicos
    },
    registrarPlanDeCuentas(empresaUuid) {
        return axios.post('/contabilidad/procesarcuentas', { empresa_uuid: empresaUuid });
    },

    delete(data) {

        return axios.delete(`${url}/${data.id}`)

    },
    indexRubroVentas() {
        const url = '/contabilidad/rubroventa'


        return axios.get(url)
    },


    createRubroVenta(data) {
        const url = '/contabilidad/rubroventa'
        return axios.post(url, data)
    },

    updateRubroVenta(data) {
        const url = '/contabilidad/rubroventa'
        return axios.put(`${url}/${data.id}`, data)

    },

    deleteRubro(data) {
        const url = '/contabilidad/rubroventa'

        return axios.delete(`${url}/${data.id}`)
    },
    indexRubroCompras() {
        const url = '/contabilidad/rubrocompra'


        return axios.get(url)
    },


    createRubroCompra(data) {
        const url = '/contabilidad/rubrocompra'
        return axios.post(url, data)
    },

    updateRubroCompra(data) {
        const url = '/contabilidad/rubrocompra'
        return axios.put(`${url}/${data.id}`, data)

    },

    deleteRubroCompra(data) {
        const url = '/contabilidad/rubrocompra'

        return axios.delete(`${url}/${data.id}`)
    },


    show(id) {
        return axios.get(`${url}/${id}`)
    },


}