import axios from 'axios'
const url = '/comprobantes/asientos'


export default {
    create(data){

        return axios.post(url, data)
    },
    update(data) {
        return axios.put(`${url}/${data.id}`, data)
    },
    index(paginacion, dateFilter){
        const url = `/comprobantes/asientos?page=${paginacion.page}&page_size=${paginacion.rowsPerPage}&${dateFilter}`

        return axios.get(url)
    },
    delete(data){

        return axios.delete(`${url}/${data.id}`)

    },    
    show(id) {
        return axios.get(`${url}/${id}`)
    },
   

}